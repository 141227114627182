import { createContext, useContext, useRef } from 'react'
import { type ViewState } from 'react-map-gl'
import {
	type StateCreator,
	createStore as createZustandStore,
	useStore as useZustandStore,
} from 'zustand'
import { type FetchedPlot } from './types'

interface StoreProps {
	debugMode: boolean
	isSheetExpanded: boolean
	isMapLoaded: boolean
	viewState: ViewState
	loadedPlots: FetchedPlot[]
	selectedPlotIds: string[]
	highlightedPlotIds: string[]
	highlightedPlotGroupIds: string[]
	favoritePlotIds: string[]
	favoritePlotGroupIds: string[]
	smallClusterPlotIds: string[]
	mediumClusterPlotIds: string[]
	largeClusterPlotIds: string[]
	largestClusterPlotIds: string[]
	fetchedPlotIds: {
		plotId: string
		fetchedAt: number // unix timestamp to check if data is stale
	}[]
}

interface StoreState extends StoreProps {
	setDebugMode: (debugMode: boolean) => void

	setIsMapLoaded: (isMapLoaded: boolean) => void

	setIsSheetExpanded: (isSheetExpanded: boolean) => void

	setViewState: (viewState: ViewState) => void
	setViewStatePadding: (padding: Partial<ViewState['padding']>) => void

	getLoadedPlot: (plotId: string) => FetchedPlot | undefined
	setLoadedPlots: (loadedPlots: StoreProps['loadedPlots']) => void
	addLoadedPlot: (plot: FetchedPlot) => void
	addLoadedPlots: (plots: FetchedPlot[]) => void
	updateLoadedPlot: (plot: FetchedPlot) => void
	updateLoadedPlots: (plots: FetchedPlot[]) => void
	updateLoadedPlotsUser: (
		userId: string,
		user: Partial<FetchedPlot['user']>,
	) => void
	updateLoadedGroupPlots: (
		groupId: string,
		plots: Partial<FetchedPlot['group']>,
	) => void

	getUserPlots(userId: string): FetchedPlot[]

	getSelectedPlotId: (plotId: string) => string | undefined
	setSelectedPlotIds: (selectedPlotIds: StoreProps['selectedPlotIds']) => void
	addSelectedPlotId: (selectedPlotId: string) => void
	removeSelectedPlotId: (selectedPlotId: string) => void

	setHighlightedPlotIds: (
		highlightedPlotIds: StoreProps['highlightedPlotIds'],
	) => void
	addHighlightedPlotId: (highlightedId: string) => void
	removeHighlightedPlotId: (highlightedId: string) => void

	getHighlightedPlotGroupId: (groupId: string) => string | undefined
	setHighlightedPlotGroupIds: (
		highlightedPlotGroupIds: StoreProps['highlightedPlotGroupIds'],
	) => void
	addHighlightedPlotGroupId: (highlightedPlotGroupId: string) => void
	removeHighlightedPlotGroupId: (highlightedPlotGroupId: string) => void

	addFavoritePlotId: (favoritePlotId: string) => void
	removeFavoritePlotId: (favoritePlotId: string) => void

	addFavoritePlotGroupId: (favoritePlotGroupId: string) => void
	removeFavoritePlotGroupId: (favoritePlotGroupId: string) => void

	addSmallClusterPlotIds: (
		smallClusterPlotIds: StoreProps['smallClusterPlotIds'],
	) => void

	addMediumClusterPlotIds: (
		mediumClusterPlotIds: StoreProps['mediumClusterPlotIds'],
	) => void

	setLargeClusterPlotIds: (
		largeClusterPlotIds: StoreProps['largeClusterPlotIds'],
	) => void

	setLargestClusterPlotIds: (
		largestClusterPlotIds: StoreProps['largestClusterPlotIds'],
	) => void

	setFetchedPlotIds: (fetchedPlotIds: StoreProps['fetchedPlotIds']) => void
}

type Store = ReturnType<typeof createStore>

type InitStoreProps = Partial<StoreProps>

const localStorageKey = 'erda:selectedPlotIds'

const persistMiddleware =
	<T extends StoreState>(config: StateCreator<T>): StateCreator<T> =>
	(set, get, api) =>
		config(
			(nextState, ...args) => {
				if (typeof nextState === 'function') {
					// Handling function updates
					const nextStateResult = nextState(get())
					if ('selectedPlotIds' in nextStateResult) {
						localStorage.setItem(
							localStorageKey,
							JSON.stringify(nextStateResult.selectedPlotIds),
						)
					}
				} else if ('selectedPlotIds' in nextState) {
					// Handling object updates
					localStorage.setItem(
						localStorageKey,
						JSON.stringify(nextState.selectedPlotIds),
					)
				}
				set(nextState, ...args)
			},
			get,
			api,
		)

export const createStore = (initProps?: InitStoreProps) => {
	const isBrowser = typeof window !== 'undefined'
	const savedSelectedPlotIds = isBrowser
		? (JSON.parse(localStorage.getItem(localStorageKey) || '[]') as string[])
		: []

	const DEFAULT_PROPS: StoreProps = {
		debugMode: false,
		isMapLoaded: false,
		isSheetExpanded: true,
		viewState: {
			latitude: 0,
			longitude: 0,
			zoom: 0,
			bearing: 0,
			pitch: 0,
			padding: {
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
			},
		},
		loadedPlots: [],
		selectedPlotIds: savedSelectedPlotIds,
		highlightedPlotIds: [],
		highlightedPlotGroupIds: [],
		favoritePlotIds: [],
		favoritePlotGroupIds: [],
		smallClusterPlotIds: [],
		mediumClusterPlotIds: [],
		largeClusterPlotIds: [],
		largestClusterPlotIds: [],
		fetchedPlotIds: [],
	}

	return createZustandStore<StoreState>()(
		persistMiddleware((set, get) => ({
			...DEFAULT_PROPS,
			...initProps,

			setDebugMode: (debugMode: boolean) => set({ debugMode }),

			setIsMapLoaded: (isMapLoaded: boolean) => set({ isMapLoaded }),

			setIsSheetExpanded: (isSheetExpanded: boolean) =>
				set({ isSheetExpanded }),

			setViewState: (viewState: ViewState) => set({ viewState }),

			setViewStatePadding: (padding: Partial<ViewState['padding']>) =>
				set(state => ({
					viewState: {
						...state.viewState,
						padding: {
							...state.viewState?.padding,
							...padding,
						},
					},
				})),

			getLoadedPlot: (plotId: string) =>
				get().loadedPlots.find(p => p.plotId === plotId),
			setLoadedPlots: (loadedPlots: FetchedPlot[]) =>
				set(state => ({
					loadedPlots: [
						...state.loadedPlots.filter(
							plot =>
								!loadedPlots.some(newPlot => newPlot.plotId === plot.plotId),
						),
						...loadedPlots,
					],
				})),
			addLoadedPlot: (plot: FetchedPlot) =>
				set(state => ({
					loadedPlots: [
						...state.loadedPlots.filter(p => p.plotId !== plot.plotId),
						plot,
					],
				})),
			addLoadedPlots: (plots: FetchedPlot[]) =>
				set(state => ({
					loadedPlots: [
						...state.loadedPlots.filter(
							plot => !plots.some(newPlot => newPlot.plotId === plot.plotId),
						),
						...plots,
					],
				})),
			updateLoadedPlot: (plot: FetchedPlot) =>
				set(state => ({
					loadedPlots: state.loadedPlots.map(p =>
						p.plotId === plot.plotId ? plot : p,
					),
				})),
			updateLoadedPlots: (plots: FetchedPlot[]) =>
				set(state => ({
					loadedPlots: state.loadedPlots.map(
						p => plots.find(newPlot => newPlot.plotId === p.plotId) || p,
					),
				})),
			updateLoadedPlotsUser: (
				userId: string,
				user: Partial<FetchedPlot['user']>,
			) =>
				set(state => ({
					loadedPlots: state.loadedPlots.map(p =>
						p.user.id === userId
							? {
									...p,
									user: {
										...p.user,
										...user,
									},
								}
							: p,
					),
				})),
			updateLoadedGroupPlots: (
				groupId: string,
				group: Partial<FetchedPlot['group']>,
			) =>
				set(state => ({
					loadedPlots: state.loadedPlots.map(p =>
						p.group?.id === groupId
							? {
									...p,
									group: {
										...p.group,
										...group,
									},
								}
							: p,
					),
				})),

			getUserPlots: (userId: string) =>
				get().loadedPlots.filter(p => p.user.id === userId),

			getSelectedPlotId: (plotId: string) =>
				get().selectedPlotIds.find(id => id === plotId),
			setSelectedPlotIds: (selectedPlotIds: string[]) =>
				set({ selectedPlotIds }),
			addSelectedPlotId: (selectedPlotId: string) =>
				set(state => ({
					selectedPlotIds: [...state.selectedPlotIds, selectedPlotId],
				})),
			removeSelectedPlotId: (selectedPlotId: string) =>
				set(state => ({
					selectedPlotIds: state.selectedPlotIds.filter(
						id => id !== selectedPlotId,
					),
				})),

			setHighlightedPlotIds: highlightedPlotIds => set({ highlightedPlotIds }),
			addHighlightedPlotId: (highlightedId: string) =>
				set(state => ({
					highlightedPlotIds: [...state.highlightedPlotIds, highlightedId],
				})),
			removeHighlightedPlotId: (highlightedId: string) =>
				set(state => ({
					highlightedPlotIds: state.highlightedPlotIds.filter(
						id => id !== highlightedId,
					),
				})),

			setHighlightedPlotGroupIds: highlightedPlotGroupIds =>
				set({ highlightedPlotGroupIds }),
			getHighlightedPlotGroupId: (plotGroupId: string) =>
				get().highlightedPlotGroupIds.find(id => id === plotGroupId),
			addHighlightedPlotGroupId: (highlightedPlotGroupId: string) =>
				set(state => ({
					highlightedPlotGroupIds: [
						...state.highlightedPlotGroupIds,
						highlightedPlotGroupId,
					],
				})),
			removeHighlightedPlotGroupId: (highlightedPlotGroupId: string) =>
				set(state => ({
					highlightedPlotGroupIds: state.highlightedPlotGroupIds.filter(
						id => id !== highlightedPlotGroupId,
					),
				})),

			addFavoritePlotId: (favoritePlotId: string) =>
				set(state => ({
					favoritePlotIds: [...state.favoritePlotIds, favoritePlotId],
				})),
			removeFavoritePlotId: (favoritePlotId: string) =>
				set(state => ({
					favoritePlotIds: state.favoritePlotIds.filter(
						id => id !== favoritePlotId,
					),
				})),

			addFavoritePlotGroupId: (favoritePlotGroupId: string) =>
				set(state => ({
					favoritePlotGroupIds: [
						...state.favoritePlotGroupIds,
						favoritePlotGroupId,
					],
				})),
			removeFavoritePlotGroupId: (favoritePlotGroupId: string) =>
				set(state => ({
					favoritePlotGroupIds: state.favoritePlotGroupIds.filter(
						id => id !== favoritePlotGroupId,
					),
				})),

			addSmallClusterPlotIds: smallClusterPlotIds =>
				set(state => ({
					smallClusterPlotIds: [
						...state.smallClusterPlotIds,
						...smallClusterPlotIds.filter(
							id => !state.smallClusterPlotIds.includes(id),
						),
					],
				})),

			addMediumClusterPlotIds: mediumClusterPlotIds =>
				set(state => ({
					mediumClusterPlotIds: [
						...state.mediumClusterPlotIds,
						...mediumClusterPlotIds.filter(
							id => !state.mediumClusterPlotIds.includes(id),
						),
					],
				})),

			setLargeClusterPlotIds: largeClusterPlotIds =>
				set({ largeClusterPlotIds }),

			setLargestClusterPlotIds: largestClusterPlotIds =>
				set({ largestClusterPlotIds }),

			setFetchedPlotIds: fetchedPlotIds =>
				set(state => ({
					fetchedPlotIds: [
						...state.fetchedPlotIds.filter(
							fetched =>
								!fetchedPlotIds.some(
									newFetched => newFetched.plotId === fetched.plotId,
								),
						),
						...fetchedPlotIds,
					],
				})),
		})),
	)
}

type StoreProviderProps = React.PropsWithChildren<InitStoreProps>

export function StoreProvider({ children, ...props }: StoreProviderProps) {
	const storeRef = useRef<Store>()
	if (!storeRef.current) {
		storeRef.current = createStore(props)
	}
	return (
		<StoreContext.Provider value={storeRef.current}>
			{children}
		</StoreContext.Provider>
	)
}

export const StoreContext = createContext<Store | null>(null)

export function useStoreContext<T>(selector: (state: StoreState) => T): T {
	const store = useContext(StoreContext)
	if (!store) throw new Error('Missing StoreContext.Provider in the tree')
	return useZustandStore(store, selector)
}
